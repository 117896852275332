<template>
  <div class="fastener-selection">
    <div class="fastener-selection__header">
      <div class="text">{{ modalTitle }}</div>
      <div class="close" @click="close">
        <img src="@/assets/img/icons/fastener--selection-close.svg" alt="❌" />
      </div>
    </div>
    <div class="fastener-selection__items">
      <div v-for="fastener in fastenersList" :key="fastener.id" class="item">
        <input
          type="radio"
          :id="`fastener-${fastener.id}`"
          :value="fastener.id"
          class="item__input"
          v-model="computedFastenerId"
          name="fastener-selection"
        />
        <label :for="`fastener-${fastener.id}`" class="item__label">
          <span class="text">{{ fastener.name }}</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { filterFasteners } from '@/utils'
import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    isAddNewFastener: {
      type: Boolean
    },
    fastenerId: Number,
    layerIndex: Number,
    itemIndex: Number,
    isInCustom: Boolean,
    availableFasteners: Array,
    placement: String,
    baseTypeId: Number
  },
  data: () => ({
    selectedFastener: null
  }),
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_SYSTEM_LAYER_FASTENER_PARAM: 'UPDATE_SECTOR_SYSTEM_LAYER_FASTENER_PARAM',
      UPDATE_JUNCTION_FASTENER_SELECTED: 'UPDATE_JUNCTION_FASTENER_SELECTED',
      ADD_FASTENER_TO_LAYER: 'ADD_FASTENER_TO_LAYER'
    }),
    close() {
      this.$emit('close')
    }
  },
  computed: {
    ...mapState({
      allFasteners: state => state.fasteners
    }),
    fastenersList() {
      return this.isInCustom ? this.filteredFastenersCustom : this.filteredFastenersDefault
    },
    filteredFastenersDefault() {
      return this.allFasteners.filter(p => {
        return this.availableFasteners.includes(p.id)
      })
    },
    filteredFastenersCustom() {
      return filterFasteners(this.baseTypeId, this.placement, this.allFasteners)
    },
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    modalTitle() {
      return this.isAddNewFastener
        ? this.$t('message.fastenerInfo.titleAdd')
        : this.$t('message.fastenerInfo.title')
    },
    computedFastenerId: {
      get() {
        return this.fastenerId || null
      },
      set(value) {
        if (this.isAddNewFastener) {
          this.ADD_FASTENER_TO_LAYER({
            sectorIndex: this.sectorIndex,
            layerIndex: this.layerIndex,
            itemIndex: this.itemIndex,
            fastenerId: value,
            fastenerRate: this.sectorFromState.layers[this.layerIndex].items[this.itemIndex]
              .material.fastenersRate
            // value: this.allFasteners.find(p => p.id === value).rate,
          })
        } else {
          if (this.placement === 'system') {
            this.UPDATE_SECTOR_SYSTEM_LAYER_FASTENER_PARAM({
              sectorIndex: this.sectorIndex,
              layerIndex: this.layerIndex,
              itemIndex: this.itemIndex,
              param: 'selected',
              value: value
            })
          } else {
            this.UPDATE_JUNCTION_FASTENER_SELECTED({
              sectorIndex: this.sectorIndex,
              layerIndex: this.layerIndex,
              itemIndex: this.itemIndex,
              junctionIndex: this.junctionIndex,
              id: value
            })
          }
        }
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="sass">
.fastener-selection
  +media((max-width: (320: 92vw, 450: rem(400))))
  +media((width: (320: 92vw, 450: rem(400))))
  &__header
    @extend .fs-14
    color: $black
    font-weight: bold
    +media((padding: (320: 0 0 0 rem(16), 768: 0 0 0 rem(20))))
    box-shadow: inset 0 rem(-1) 0 #ECECEC
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    .close
      height: rem(56)
      width: rem(40)
      cursor: pointer
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
  &__items
    +media((padding: (320: rem(8) rem(16), 768: rem(16) rem(20))))
    .item
      &:not(:last-child)
        box-shadow: inset 0 rem(-1) 0 #ECECEC
      &__input
        position: absolute
        pointer-events: none
        display: none
        opacity: 0
        &:checked
          + label
            background-color: $red
            border-radius: rem(4)
            box-shadow: none
            background-image: url(~@/assets/img/icons/fastener--selection.svg)
            .text
              color: #ffffff
              font-weight: 700
              +media((padding-left: (320: rem(16), 768: rem(20))))
      &__label
        min-height: rem(40)
        cursor: pointer
        display: flex
        flex-direction: row
        align-items: center
        padding: 0 rem(20)
        transition: .5s
        background-position: rem(14) center
        background-repeat: no-repeat
        .text
          transition: .5s
          @extend .fs-12
          color: $black
        &:hover
          background-color: #EA454E

          border-radius: rem(4)
          box-shadow: none
          background-image: url(~@/assets/img/icons/fastener--selection.svg)
          .text
            +media((padding-left: (320: rem(16), 768: rem(20))))
            color: #ffffff
</style>
