<template>
  <div class="controls">
    <div
      class="layer--input-with-units"
      :class="{ 'layer--input-with-units--disabled': item.material.isDisabled }"
      v-if="item.material.valueType === 'input'"
    >
      <!--&& !isInCustom-->
      <!--<div class="value" v-if="item.material.isDisabled && !isInCustom">
        {{ item.material.value }}
      </div>-->
      <div class="value" v-if="item.material.isDisabled">
        {{ item.material.value }}
      </div>
      <input type="number" v-else min="0" max="1000" step="0.1" v-model.number="computedValue" />
      <!--@input="updateLayerItemValueInput($event, i, n)"-->
      <span class="units">{{ $t('message.units.mm') }}</span>
    </div>
    <div
      class="layer-select"
      :class="{ 'layer-select--disabled': currentOptions.length === 1 }"
      v-if="item.material.valueType === 'select'"
    >
      <div class="value" v-if="currentOptions.length === 1">
        {{ currentOptions[0] }} {{ $t('message.units.mm') }}
      </div>
      <select v-model.number="computedValue" v-else>
        <option
          :value="option"
          :name="`child-layer-${layerIndex}-number-${itemIndex}`"
          v-for="(option, n) in currentOptions"
          :key="`option-${n}-layer-${layerIndex}`"
        >
          {{ option }} {{ $t('message.units.mm') }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    item: Object,
    layerIndex: Number,
    itemIndex: Number,
    isInCustom: Boolean,
    parentType: String
  },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_SYSTEM_LAYER_ITEM_VALUE: 'UPDATE_SECTOR_SYSTEM_LAYER_ITEM_VALUE',
      UPDATE_JUNCTION_LAYER_ITEM_VALUE: 'UPDATE_JUNCTION_LAYER_ITEM_VALUE'
    })
  },
  computed: {
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    junctionIndex() {
      return this.$route.params.number ? this.$route.params.number : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    isInSystem() {
      return this.parentType === 'system'
    },
    currentOptions() {
      if (this.isInSystem) {
        return this.item.material.options
      } else {
        return this.isInCustom ? this.item.material.options : this.item.material.allowedOptions
      }
    },
    computedValue: {
      get() {
        return this.item.material.value
      },
      set(value) {
        if (!isNaN(parseFloat(value)) && value >= 0) {
          if (this.isInSystem) {
            this.UPDATE_SECTOR_SYSTEM_LAYER_ITEM_VALUE({
              sectorIndex: this.sectorIndex,
              layerIndex: this.layerIndex,
              itemIndex: this.itemIndex,
              value: value
            })
          } else {
            this.UPDATE_JUNCTION_LAYER_ITEM_VALUE({
              sectorIndex: this.sectorIndex,
              layerIndex: this.layerIndex,
              itemIndex: this.itemIndex,
              junctionIndex: this.junctionIndex,
              value: value
            })
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="sass">
// .controls
  input, select
    +media((font-size: (320: rem(12), 960: rem(14))))
</style>
